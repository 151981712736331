﻿.rss > #marquee {
    background-color: #ecf1eb;
    letter-spacing: 0.2pt;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    font-weight: 700;
    /*margin: 0 2vh 3vh 2vh;*/
    /*font-size: 3vh;*/
    padding: 1vh;
    /*border-radius: 1.5vh;*/
}

.rss > h1 {
    font-weight: 200;
    text-align: center;
    font-size: calc(6vw + 6vh + 3vmin);
    background: -webkit-linear-gradient(#0f0c29, #302b63, #24243e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
