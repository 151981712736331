/*(1) OpenSans-Regular-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-Regular-webfont.eot");
  src: url("/fonts/OpenSans-Regular-webfont.eot?#iefix"), url("/fonts/OpenSans-Regular-webfont.woff") format("woff"), url("/fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Regular-webfont.svg#Open Sans Web") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*(2) OpenSans-Italic-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-Italic-webfont.eot");
  src: url("/fonts/OpenSans-Italic-webfont.eot?#iefix"), url("/fonts/OpenSans-Italic-webfont.woff") format("woff"), url("/fonts/OpenSans-Italic-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Italic-webfont.svg#Open Sans Web") format("svg");
  font-weight: normal;
  font-style: italic;
}
/*(3) OpenSans-Bold-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-Bold-webfont.eot");
  src: url("/fonts/OpenSans-Bold-webfont.eot?#iefix"), url("/fonts/OpenSans-Bold-webfont.woff") format("woff"), url("/fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Bold-webfont.svg#Open Sans Web") format("svg");
  font-weight: bold;
  font-style: normal;
}
/*(4) OpenSans-BoldItalic-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-BoldItalic-webfont.eot");
  src: url("/fonts/OpenSans-BoldItalic-webfont.eot?#iefix"), url("/fonts/OpenSans-BoldItalic-webfont.woff") format("woff"), url("/fonts/OpenSans-BoldItalic-webfont.ttf") format("truetype"), url("/fonts/OpenSans-BoldItalic-webfont.svg#Open Sans Web") format("svg");
  font-weight: bold;
  font-style: italic;
}
/*(5) OpenSans-ExtraBold-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-ExtraBold-webfont.eot");
  src: url("/fonts/OpenSans-ExtraBold-webfont.eot?#iefix"), url("/fonts/OpenSans-ExtraBold-webfont.woff") format("woff"), url("/fonts/OpenSans-ExtraBold-webfont.ttf") format("truetype"), url("/fonts/OpenSans-ExtraBold-webfont.svg#Open Sans Web") format("svg");
  font-weight: 800;
  font-style: normal;
}
/*(6) OpenSans-ExtraBoldItalic-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-ExtraBoldItalic-webfont.eot");
  src: url("/fonts/OpenSans-ExtraBoldItalic-webfont.eot?#iefix"), url("/fonts/OpenSans-ExtraBoldItalic-webfont.woff") format("woff"), url("/fonts/OpenSans-ExtraBoldItalic-webfont.ttf") format("truetype"), url("/fonts/OpenSans-ExtraBoldItalic-webfont.svg#Open Sans Web") format("svg");
  font-weight: 800;
  font-style: italic;
}
/*(7) OpenSans-Light-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-Light-webfont.eot");
  src: url("/fonts/OpenSans-Light-webfont.eot?#iefix"), url("/fonts/OpenSans-Light-webfont.woff") format("woff"), url("/fonts/OpenSans-Light-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Light-webfont.svg#Open Sans Web") format("svg");
  font-weight: 300;
  font-style: normal;
}
/*(8) OpenSans-LightItalic-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-LightItalic-webfont.eot");
  src: url("/fonts/OpenSans-LightItalic-webfont.eot?#iefix"), url("/fonts/OpenSans-LightItalic-webfont.woff") format("woff"), url("/fonts/OpenSans-LightItalic-webfont.ttf") format("truetype"), url("/fonts/OpenSans-LightItalic-webfont.svg#Open Sans Web") format("svg");
  font-weight: 300;
  font-style: italic;
}
/*(9) OpenSans-Semibold-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-Semibold-webfont.eot");
  src: url("/fonts/OpenSans-Semibold-webfont.eot?#iefix"), url("/fonts/OpenSans-Semibold-webfont.woff") format("woff"), url("/fonts/OpenSans-Semibold-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Semibold-webfont.svg#Open Sans Web") format("svg");
  font-weight: 600;
  font-style: normal;
}
/*(10) OpenSans-SemiboldItalic-webfont
-----------------------------------------------*/
@font-face {
  font-family: "Open Sans Web";
  src: url("/fonts/OpenSans-SemiboldItalic-webfont.eot");
  src: url("/fonts/OpenSans-SemiboldItalic-webfont.eot?#iefix"), url("/fonts/OpenSans-SemiboldItalic-webfont.woff") format("woff"), url("/fonts/OpenSans-SemiboldItalic-webfont.ttf") format("truetype"), url("/fonts/OpenSans-SemiboldItalic-webfont.svg#Open Sans Web") format("svg");
  font-weight: 600;
  font-style: italic;
}
