﻿


.priceplan_header0{
    background:rgb(217,226,243);
}

.priceplan_content0{
    background:rgb(222,234,246);
}

.priceplan_title0 {
    color: #FFF;
    background:rgb(217,226,243);
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 30px 0;
}

.descriptiontext0 {
    color:#000000;
}

.pricetext0 {
    color:#000000;
}

.price-plan .price-box {
  background: #f7f7f7;
  padding: 30px 0;
}



.priceplan_header1{
    background-color:rgb(180,198,231);
}

.priceplan_content1{
    background-color:rgb(189,214,238);
}

.priceplan_title1 {
    color: #FFF;
    background:rgb(180,198,231);
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 30px 0;
}

.descriptiontext1 {
    color:#000000;
}

.pricetext1 {
    color:#000000;
}


.priceplan_header2{
    background-color:rgb(142,170,219);
}

.priceplan_content2{
    background-color:rgb(156,194,229);
}

.priceplan_title2 {
    color: #FFF;
    background:rgb(142,170,219);
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 30px 0;
}

.descriptiontext2 {
    color:#000000;
}

.pricetext2 {
    color:#000000;
}

.priceplan_header3{
    background-color:rgb(47,84,150);
}

.priceplan_content3{
    background-color:rgb(46,116,181);
}

.priceplan_title3 {
    color: #FFF;
    background:rgb(47,84,150);
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 30px 0;
}

.descriptiontext3 {
    color:#FFFFFF;
}

.pricetext3 {
    color:#000000;
}

.priceplan_header4{
    background-color:rgb(31,56,100);
}

.priceplan_content4{
    background-color:rgb(31,78,121);
}

.priceplan_title4 {
    color: #FFF;
    background:rgb(31,56,100);
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 30px 0;
}

.descriptiontext4 {
    color:#FFFFFF;
}

.pricetext4 {
    color:#000000;
}
