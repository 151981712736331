﻿.modal.fade .modal-dialog {
    transition: -webkit-transform 0.0s ease-out;
    transition: transform 0.0s ease-out;
    transition: transform 0.0s ease-out, -webkit-transform 0.0s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}


.fade {
    transition: opacity 0.0s linear;
}

