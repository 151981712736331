﻿body {
}

.moduleDiv {
    position: absolute;
    background-color: black;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    cursor: pointer;
    z-index: 1000;
}

    .moduleDiv i {
        color: white;
        cursor: pointer;
        z-index: 1000;
    }

.moduleEditDiv {
    position: absolute;
    background-color: black;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    margin-right: 40px;
    cursor: pointer;
    z-index: 1000;
}

    .moduleEditDiv i {
        color: white;
        cursor: pointer;
        z-index: 1000;
    }


.settingDiv {
    position: absolute;
    background-color: black;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    margin-right: 80px;
    cursor: pointer;
    z-index: 1000;
}

    .settingDiv i {
        color: white;
        cursor: pointer;
        z-index: 1000;
    }

.emptyDiv {
    position: absolute;
    background-color: black;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    margin-right: 120px;
    cursor:default;
    z-index: 1000;
}

    .emptyDiv i {
        color: white;
        cursor: pointer;
        z-index: 1000;
    }


.playDiv {
    position: absolute;
    background-color: black;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    margin-right: 160px;
    cursor: pointer;
    z-index: 1000;
}

    .playDiv i {
        color: white;
        cursor: pointer;
        z-index: 1000;
    }

.sizeDiv {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 40px;
    width: 180px;
    bottom: 0px;
    cursor: pointer;
    z-index: 1000;
}

    .sizeDiv p {
        text-align: left;
        font-size: 30px;
        background-color: black;
        text-align: center;
        height: 40px !important;
        padding-top: 9px;
        cursor: pointer;
        z-index: 1000;
        color: rgba(255,255,255,0.6);
        cursor: not-allowed;
    }


.parentBtn {
    position: relative;
}

.moduleRegion {
    position: relative;
    padding: 0 0 0 0px !important;
    overflow: hidden;
    z-index: 100;
}

.module {
    /*position:relative;*/
    top: 0px;
}

/* Edit module*/

/*.label_skitter {
    z-index: 150;
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: none;
    z-index: 150;
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: none;
    background: linear-gradient(transparent, #000);
    width: 100%
}*/

.e4us_fa_disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.div100 {
    height:100% !important;
    max-height:100% !important;
}