﻿.cropper-container {
    max-width: 960px;
    margin: 20px auto;
}

.cropper-img-container {
    width: 100%;
    max-height: 450px;
}

/*.cropper-img-container img {
        max-width: 100%;
    }*/

.cropper-img-preview {
    overflow: hidden;
}

.cropper-col {
    float: left;
}

.cropper-col-6 {
    width: 50%;
}

.cropper-col-3 {
    width: 25%;
}

.cropper-col-2 {
    width: 16.7%;
}

.cropper-col-1 {
    width: 8.3%;
}

.cropper-faceRound {
    border-radius: 50%;
}

