/* scaffolding */
/* ----------- */

/*html {
  overflow-y: scroll;
  *overflow-x: hidden;
}

.container {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}

.tt-dropdown-menu,
.gist {
  text-align: left;
}

code {
  margin: 0;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  border-radius: 3px;
  max-width: 100%;
  display: inline-block;
  overflow: auto;
  vertical-align: middle;
  line-height: 1.3;
  padding: 2px;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-size: 14px;
}*/
/* base styles */
/* ----------- */

/*html {
  font-family: 'Lato', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.2;
  color: #333;
}
label img {
  vertical-align: middle;
}
label {
  margin: 5px 0 ;
  display: block;
}
.title,
.example-name {
  font-family: Prociono;
}

p {
  margin: 0 0 10px 0;
}

h4 {
  margin: 10px 0 0 0;
}*/
/* site theme 
 ---------- 

.title {
  margin: 20px 0 0 0;
  font-size: 64px;
}

.example {
  padding: 30px 0;
}

.example-name {
  margin: 20px 0;
  font-size: 32px;
}

.demo {
  position: relative;
  *z-index: 1;
  margin: 50px 0;
}*/

.typeahead,
.tt-query,
/*.tt-hint {
  width: 480px;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  outline: none;
}*/
/*.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}
.typeahead.empty {
  border: 2px solid red;
}*/
.tt-query {
    /*-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);*/
}

.tt-hint {
    color: #999;
    /*width: 430px;*/
}

.tt-dropdown-menu {
    /*width: 422px;*/
    margin-top: 12px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    /*-webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);*/
    /*modification events4us begin*/
    width: 100%;
    /*modification events4us end*/
}

.tt-suggestion {
    padding: 3px 20px;
    /*font-size: 18px;*/
    line-height: 24px;
}

    .tt-suggestion.tt-cursor {
        color: #fff;
        background-color: #0097cf;
    }

    .tt-suggestion p {
        margin: 0;
    }

/*.gist {
  font-size: 14px;
}

#main {
  width: 1000px;
  margin: 10px auto;
}
#map {
  float:right;
  width: 490px;
  height: 500px;
  border: 1px solid #DDD;
}
.address-panel {
  float:left;
  width: 430px;

}
.typeahead {
  width: 405px;
}

pre.response {
  float:left;
  width: 430px;
  height: 400px;
  border: 1px solid #DDD;
  font-size: 12px;
  overflow: scroll;
}*/

/*modification events4us begin*/
.twitter-typeahead {
    width: 100%;
}
/*modification events4us end*/
