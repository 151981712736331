.skitter {
  position: relative;
  /*max-width: 800px;
  height: 300px;*/
  background: black
}

  .skitter.with-dots {
    margin-bottom: 60px
  }

  .skitter.with-thumbs {
    margin-bottom: 60px
  }

  .skitter > a img, .skitter > img {
    max-width: none
  }

  .skitter ul {
    display: none
  }

  .skitter .container_skitter {
    overflow: hidden;
    position: relative
  }

    .skitter .container_skitter .image_main {
      width: 100%
    }

  .skitter .image {
    overflow: hidden
  }

    .skitter .image > a img, .skitter .image > img {
      width: 100%;
      display: none
    }

  .skitter .box_clone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    overflow: hidden;
    display: none;
    z-index: 20
  }

    .skitter .box_clone img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20
    }

  .skitter .prev_button {
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
    z-index: 152;
    width: 42px;
    height: 42px;
    overflow: hidden;
    text-indent: -9999em;
    transition: all 0.2s
  }

  .skitter .next_button {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    z-index: 152;
    width: 42px;
    height: 42px;
    overflow: hidden;
    text-indent: -9999em;
    transition: all 0.2s
  }

    .skitter .prev_button:hover, .skitter .next_button:hover {
      opacity: 0.5
    }

  .skitter .info_slide {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 100;
    background-color: #000;
    color: #fff;
    font: bold 11px arial;
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    opacity: 0.75
  }

    .skitter .info_slide .image_number {
      background-color: #333;
      float: left;
      padding: 2px 10px;
      margin: 0 5px 0 0;
      cursor: pointer;
      border-radius: 2px;
      transition: all 0.2s
    }

      .skitter .info_slide .image_number:hover {
        background-color: #000
      }

      .skitter .info_slide .image_number.image_number_select {
        background-color: #ccc;
        color: black
      }

  .skitter .container_thumbs {
    position: relative;
    overflow: hidden;
    height: 50px
  }

  .skitter .info_slide_thumb {
    height: 50px;
    border-radius: 0;
    overflow: hidden;
    top: auto;
    top: 0;
    left: 0;
    padding: 0;
    opacity: 1.0
  }

    .skitter .info_slide_thumb .image_number {
      overflow: hidden;
      width: 100px;
      height: 50px;
      position: relative;
      margin: 0;
      padding: 0;
      border-radius: 0;
      background-size: cover;
      background-position: center center
    }

      .skitter .info_slide_thumb .image_number.image_number_select {
        opacity: .4
      }

  .skitter .box_scroll_thumbs {
    padding: 0
  }

    .skitter .box_scroll_thumbs .scroll_thumbs {
      position: absolute;
      bottom: 60px;
      left: 50px;
      background-color: #ccc;
      width: 200px;
      height: 10px;
      overflow: hidden;
      text-indent: -9999em;
      z-index: 101;
      cursor: pointer;
      border: 0px solid #333
    }

  .skitter .info_slide_dots {
    position: absolute;
    bottom: -40px;
    z-index: 151;
    padding: 5px 0 5px 5px;
    border-radius: 50px
  }

    .skitter .info_slide_dots .image_number {
      background-color: #999;
      float: left;
      margin: 0 5px 0 0;
      cursor: pointer;
      border-radius: 50px;
      width: 14px;
      height: 14px;
      text-indent: -9999em;
      overflow: hidden;
      transition: all 0.2s
    }

      .skitter .info_slide_dots .image_number:hover {
        background-color: #777
      }

      .skitter .info_slide_dots .image_number.image_number_select {
        background-color: #222
      }

.loading {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 10000;
  margin: -16px -16px;
  color: #fff;
  text-indent: -9999em;
  overflow: hidden;
  width: 32px;
  height: 32px
}

.label_skitter {
  z-index: 150;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: none;
  z-index: 150;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: none;
  /*background: linear-gradient(transparent, #000);*/
  width: 100%
}

  .label_skitter p {
    color: #fff;
    padding: 10px;
    margin: 0;
    font-size: 14px
  }

.progressbar {
  background-color: #000;
  position: absolute;
  top: 5px;
  left: 15px;
  height: 5px;
  width: 200px;
  z-index: 99;
  border-radius: 20px
}

.preview_slide {
  display: none;
  position: absolute;
  z-index: 152;
  bottom: 30px;
  left: -40px;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #222;
  box-shadow: rgba(0,0,0,0.7) 2px 2px 5px;
  overflow: hidden
}

  .preview_slide ul {
    height: 100px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0
  }

    .preview_slide ul li {
      width: 100px;
      height: 100px;
      overflow: hidden;
      float: left;
      margin: 0;
      padding: 0;
      position: relative;
      display: block
    }

      .preview_slide ul li img {
        position: absolute;
        top: 0;
        left: 0;
        height: 150px;
        width: auto
      }

#overlay_skitter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  opacity: 1;
  background-color: #000
}

.skitter .focus_button {
  position: absolute;
  z-index: 100;
  width: 42px;
  height: 42px;
  overflow: hidden;
  text-indent: -9999em;
  opacity: 0;
  transition: all 0.2s
}

.skitter .play_pause_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 151;
  width: 42px;
  height: 42px;
  overflow: hidden;
  text-indent: -9999em;
  opacity: 0;
  transition: all 0.2s
}

  .skitter .play_pause_button:hover {
    opacity: 0.5
  }

.skitter .focus_button:hover {
  opacity: 0.5
}

.skitter .focus_button, .skitter .next_button, .skitter .prev_button, .skitter .play_pause_button {
  display: block;
  background: url("../images/skitter/sprite-default.png") no-repeat;
  background-size: 84px auto
}

.skitter .next_button {
  background-position: -0px -42px;
  width: 42px;
  height: 42px
}

.skitter .prev_button {
  background-position: -42px -42px;
  width: 42px;
  height: 42px
}

.skitter .play_pause_button {
  background-position: -0px -0px;
  width: 42px;
  height: 42px
}

  .skitter .play_pause_button.play_button {
    background-position: -0px -84px;
    width: 42px;
    height: 42px
  }

.skitter .focus_button {
  background-position: -42px -0px;
  width: 42px;
  height: 42px;
  top: 35px;
  left: 35px
}

.skitter-clean .prev_button, .skitter-clean .next_button, .skitter-clean .play_pause_button, .skitter-clean .focus_button {
  display: block;
  background: url("../images/skitter/sprite-clean.png") no-repeat;
  background-size: 47px auto
}

.skitter-clean .prev_button {
  background-position: 0px 0px;
  width: 47px;
  height: 46px
}

.skitter-clean .next_button {
  background-position: 0px -46px;
  width: 47px;
  height: 46px
}

.skitter-clean .play_pause_button {
  background-position: -0px -133px;
  width: 41px;
  height: 41px
}

  .skitter-clean .play_pause_button.play_button {
    background-position: -0px -92px;
    width: 41px;
    height: 41px
  }

.skitter-clean .focus_button {
  background-position: -0px -174px;
  width: 41px;
  height: 41px
}

.skitter-clean .info_slide {
  background-color: transparent
}

  .skitter-clean .info_slide .image_number {
    background-color: #fff;
    box-shadow: rgba(0,0,0,0.2) 1px 1px 0;
    font-size: 12px;
    font-weight: normal;
    color: #333
  }

    .skitter-clean .info_slide .image_number:hover {
      background-color: #ccc
    }

    .skitter-clean .info_slide .image_number.image_number_select {
      background-color: #111;
      color: #fff
    }

.skitter-clean .info_slide_dots {
  background: rgba(0,0,0,0.07);
  box-shadow: rgba(0,0,0,0.3) 1px 1px 0
}

  .skitter-clean .info_slide_dots .image_number {
    width: 14px;
    height: 14px;
    background-color: #999
  }

    .skitter-clean .info_slide_dots .image_number:hover {
      background-color: #333
    }

    .skitter-clean .info_slide_dots .image_number.image_number_select {
      background-color: #111
    }

.skitter-clean .progressbar {
  top: 5px;
  left: 5px;
  background-color: #fff;
  box-shadow: rgba(0,0,0,0.5) 1px 1px 0
}

.skitter-minimalist .prev_button, .skitter-minimalist .next_button, .skitter-minimalist .play_pause_button, .skitter-minimalist .focus_button {
  display: block;
  background: url("../images/skitter/sprite-minimalist.png") no-repeat;
  background-size: 24px auto
}

.skitter-minimalist .prev_button {
  background-position: -0px -0px;
  width: 24px;
  height: 28px
}

.skitter-minimalist .next_button {
  background-position: -0px -28px;
  width: 24px;
  height: 28px
}

.skitter-minimalist .play_pause_button {
  background-position: -0px -74px;
  width: 18px;
  height: 18px;
  transform: none;
  top: 35px;
  left: 35px
}

  .skitter-minimalist .play_pause_button.play_button {
    background-position: -0px -56px;
    width: 18px;
    height: 18px
  }

.skitter-minimalist .focus_button {
  background-position: -0px -92px;
  width: 18px;
  height: 18px;
  transform: none;
  top: 35px;
  left: 95px
}

.skitter-minimalist .info_slide {
  background-color: transparent
}

  .skitter-minimalist .info_slide .image_number {
    background-color: #000;
    box-shadow: rgba(255,255,255,0.2) 1px 1px 0;
    font-size: 12px;
    font-weight: normal;
    background-color: #999
  }

    .skitter-minimalist .info_slide .image_number:hover {
      background-color: #111
    }

    .skitter-minimalist .info_slide .image_number.image_number_select {
      background-color: #ccc
    }

.skitter-minimalist .info_slide_dots .image_number {
  width: 14px;
  height: 14px;
  box-shadow: rgba(255,255,255,0.2) 1px 1px 0;
  background-color: #999
}

  .skitter-minimalist .info_slide_dots .image_number:hover {
    background-color: #111
  }

  .skitter-minimalist .info_slide_dots .image_number.image_number_select {
    background-color: #ccc
  }

.skitter-round .prev_button, .skitter-round .next_button, .skitter-round .play_pause_button, .skitter-round .focus_button {
  display: block;
  background: url("../images/skitter/sprite-round.png") no-repeat;
  background-size: 47px auto
}

.skitter-round .prev_button {
  background-position: -0px -0px;
  width: 23px;
  height: 47px;
  left: -23px
}

.skitter-round .next_button {
  background-position: -23px -0px;
  width: 23px;
  height: 47px;
  right: -23px
}

  .skitter-round .next_button:hover {
    opacity: 1
  }

.skitter-round .prev_button:hover {
  opacity: 1
}

.skitter-round .play_pause_button {
  background-position: -0px -70px;
  width: 47px;
  height: 23px;
  top: 0;
  left: 24px;
  transform: none
}

  .skitter-round .play_pause_button.play_button {
    background-position: -0px -47px;
    width: 47px;
    height: 23px
  }

.skitter-round .focus_button {
  background-position: -0px -93px;
  width: 47px;
  height: 23px;
  top: 0;
  left: 82px;
  margin-top: 0
}

.skitter-round .info_slide {
  background-color: transparent
}

  .skitter-round .info_slide .image_number {
    background-color: #777;
    box-shadow: rgba(255,255,255,0.2) 1px 1px 0;
    font-size: 12px;
    font-weight: normal
  }

    .skitter-round .info_slide .image_number:hover {
      background-color: #333
    }

    .skitter-round .info_slide .image_number.image_number_select {
      background-color: #111;
      color: white
    }

.skitter-round .info_slide_dots .image_number {
  width: 14px;
  height: 14px;
  box-shadow: rgba(255,255,255,0.2) 1px 1px 0;
  background-color: #777
}

  .skitter-round .info_slide_dots .image_number:hover {
    background-color: #333
  }

  .skitter-round .info_slide_dots .image_number.image_number_select {
    background-color: #111
  }

.skitter-round .progressbar {
  top: -10px;
  left: 0px;
  background-color: #333;
  box-shadow: rgba(255,255,255,0.3) 1px 1px 0
}

.skitter-square .play_pause_button, .skitter-square .next_button, .skitter-square .focus_button, .skitter-square .prev_button {
  display: block;
  background: url("../images/skitter/sprite-square.png") no-repeat;
  background-size: 110px auto
}

.skitter-square .play_pause_button {
  background-position: -55px -0px;
  width: 55px;
  height: 55px;
  top: 10px;
  left: 10px;
  bottom: auto;
  right: auto;
  margin-top: 0;
  transform: none
}

  .skitter-square .play_pause_button.play_button {
    background-position: -0px -0px;
    width: 55px;
    height: 55px
  }

.skitter-square .focus_button {
  background-position: -55px -55px;
  width: 55px;
  height: 55px;
  top: 10px;
  left: 65px;
  bottom: auto;
  right: auto;
  margin-top: 0;
  transform: none
}

.skitter-square .next_button {
  background-position: -0px -55px;
  width: 55px;
  height: 55px;
  top: 10px;
  left: auto;
  right: 10px;
  margin: 0;
  transform: none
}

.skitter-square .prev_button {
  background-position: -0px -110px;
  width: 55px;
  height: 55px;
  top: 10px;
  left: auto;
  right: 65px;
  margin: 0;
  transform: none
}

.skitter-square .info_slide {
  background-color: transparent
}

  .skitter-square .info_slide .image_number {
    background-color: #fff;
    box-shadow: rgba(0,0,0,0.2) 1px 1px 0;
    font-size: 12px;
    font-weight: normal;
    color: #333
  }

    .skitter-square .info_slide .image_number:hover {
      background-color: #ccc
    }

    .skitter-square .info_slide .image_number.image_number_select {
      background-color: #111;
      color: #fff
    }

.skitter-square .info_slide_dots .image_number {
  width: 14px;
  height: 14px;
  box-shadow: rgba(255,255,255,0.2) 1px 1px 0;
  background-color: #ccc
}

  .skitter-square .info_slide_dots .image_number:hover {
    background-color: #999
  }

  .skitter-square .info_slide_dots .image_number.image_number_select {
    background-color: #555
  }

.skitter-square .progressbar {
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 0;
  height: 2px
}

@media (max-width: 1024px) {
  .skitter .info_slide_dots, .skitter .info_slide {
    display: none
  }
}

.skitter-spinner {
  padding: 10px 12px;
  border-radius: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

  .skitter-spinner .icon-sending {
    display: inline-block;
    animation: rotateMe 500ms linear infinite;
    border-radius: 100%;
    border: 2px solid white;
    border-bottom-color: transparent;
    width: 20px;
    height: 20px;
    vertical-align: top
  }

@keyframes rotateMe {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}
