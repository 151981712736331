﻿.approtateright {
    /* Safari */
    -webkit-transform: rotate(12deg);
    /* Firefox */
    -moz-transform: rotate(12deg);
    /* IE */
    -ms-transform: rotate(12deg);
    /* Opera */
    -o-transform: rotate(12deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.approtateleft {
    /* Safari */
    -webkit-transform: rotate(-12deg);
    /* Firefox */
    -moz-transform: rotate(-12deg);
    /* IE */
    -ms-transform: rotate(-12deg);
    /* Opera */
    -o-transform: rotate(-12deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-3);
}
