﻿@base: #ff0000;
@hover: #c80000;

/*@import (inline) '../webpageeditor/ckeditor5-build-inline/sample/css/sample.css';*/

@import '../../bower_components/bootstrap/dist/css/bootstrap.css';
@import '../../bower_components/font-awesome/less/fontawesome.less';
@import '../../bower_components/dragula/dist/dragula.css';
@import '../../bower_components/bootstrap-pincode-input/css/bootstrap-pincode-input.css';

@import (inline) './css/flipdown.css';

/*@import '../../bower_components/font-awesome/css/all.css';*/

@import '../../bower_components/filepond/dist/filepond.css';
@import (inline) './css/filepond-plugin-image-preview.css';
@import (inline) './css/filepond-plugin-image-edit.css';

//Open sans font from template could not find the correct bower package
@import (inline) './fonts/open-sans/open-sans.css';

@import (inline) './css/infopage.css';
@import (inline) './css/rss-marquee.css';
@import (inline) './css/w3fclocktimer.css';
@import (inline) './css/flatWeatherPlugin.css';


@import (inline) './css/prettyPhoto.css';
/*@import (inline) './css/flags.css';
@import (inline) './css/bxslider.css';*/
@import (inline) '../../bower_components/fancybox/dist/jquery.fancybox.css';
@import (inline) '../../bower_components/skitter/dist/skitter.css';
@import (inline) '../../bower_components/bootstrap-duration-picker/dist/bootstrap-duration-picker.css';
//jqplot
@import (inline) '../../bower_components/jqplot-bower/dist/jquery.jqplot.css';
@import (inline) './css/jqplot.css';
//Jquery toast
@import (inline) '../../bower_components/jquery-toast-plugin/dist/jquery.toast.min.css';
//required by portal
//nouislider
@import (inline) '../../bower_components/nouislider/distribute/nouislider.css';
//bootstrap-switch
//@import '../../bower_components/bootstrap-switch/src/less/bootstrap3/bootstrap-switch.less';
@import '../../bower_components/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css';

@import (inline) '../../bower_components/bootstrap-colorpicker/dist/bootstrap-colorpicker.css';
//bower module, sample css
@import (inline) './css/typeahead-addresspicker.css';
//datatables for delete and expand an element
@import (inline) './css/datatables.css';
//UI
//@import (inline) '../../bower_components/jquery-ui/themes/ui-lightness/jquery-ui.css';
//@import (inline) '../../bower_components/jquery-ui/themes/ui-lightness/theme.css';
@import (inline) './css/animation.css';

@import (inline) './css/jquery.fontselect.min.css';
//datatables
/*@import (inline) '../../bower_components/datatables/media/css/dataTables.bootstrap.css';*/
@import (inline) '../../bower_components/datatables/media/css/dataTables.bootstrap4.css';
/*@import (inline) '../../bower_components/datatables/media/js/plugins/integration/bootstrap/3/dataTables.bootstrap.css';*/
/*@import (inline) '../../bower_components/datatables-tabletools/css/dataTables.tableTools.css';*/
@import (inline) '../../bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';

@import (inline) '../../bower_components/cropperjs/dist/cropper.css';

@import (inline) '../../bower_components/owl-carousel2/dist/assets/owl.carousel.css';
@import (inline) '../../bower_components/owl-carousel2/dist/assets/owl.theme.default.css';

//@import (inline) '../../bower_components/tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4-build.scss';
@import (inline) '../../bower_components/bootstrap-timepicker/css/timepicker.less';

@import (inline) './css/cropper.css';
/*@import (inline) './css/default.css';*/
/*@import './css/default.less';*/
/*@import (inline) './css/portal.css';*/
/*@import (inline) './css/bootstrapChange.css';*/
@import (inline) './css/chtmlChange.css';
@import (inline) './css/priceplan.css';
@import (inline) './css/webcontent.css';
@import (inline) './css/index.css';

@import (inline) './css/template.css';

@import (inline) './css/animate.css';

@import (inline) './css/materialdesignicons.min.css';

@import (inline) '../../bower_components/bootstrap-select/dist/css/bootstrap-select.css';

@import (inline) './css/module.css';

/*E4us change*/
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 10px;
    top: 100%;
    left: 0;
    width: 100%;
}
/*E4us change*/

@import './css/style.css';
/* PAGINATION
-------------------------------------------------- */
div.dataTables_paginate .pagination li:first-child a,
div.dataTables_paginate .pagination li:first-child span,
div.dataTables_paginate .pagination li:last-child a,
div.dataTables_paginate .pagination li:last-child span,
div.dataTables_paginate .pagination li a {
    border: none;
    background: none;
    border: 1px solid #ddd;
    padding: 0 14px;
    line-height: 35px;
    display: block;
    margin-right: 5px;
    color: #323a45;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
}

div.dataTables_paginate div.dataTables_paginate .pagination li a:hover {
    background: #e5e5e5;
}

@media (max-width: 767px) {
    div.dataTables_paginate .pagination li a {
        padding: 0 14px;
        line-height: 35px;
    }
}

div.dataTables_paginate .pagination li:first-child a {
    font-size: 15px;
    border-color: #ddd;
}

div.dataTables_paginate .pagination li:first-child a:hover {
}

div.dataTables_paginate .pagination li:last-child a {
    font-size: 15px;
}

div.dataTables_paginate .pagination li:last-child a:hover {
}

div.dataTables_paginate .pagination.align-center {
    width: 100%;
}

div.dataTables_paginate .pagination.align-center li {
    display: inline-block;
}

div.dataTables_paginate .pagination.align-center li a {
    margin-right: 1px;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px
}

.pagination > li {
    display: inline
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #d1e751;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    z-index: 3;
    color: #b5cf1d;
    background-color: #eee;
    border-color: #ddd
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 2;
    color: #fff;
    background-color: #464dee;
    border-color: #464dee;
    cursor: default
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
    color: #777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed
}


.form-group {
    margin-bottom:6px;
}



.tooltip {
    opacity: 1 !important;
}

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 9;
}

.ribbon {
    font: bold 15px Sans-Serif;
    color: #333;
    text-align: center;
    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    left: -5px;
    top: 15px;
    width: 120px;
    background-color: #BFDC7A;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
    background-image: -webkit-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45);
    color: #6a6340;
    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

.ribbon:before, .ribbon:after {
    content: "";
    border-top: 3px solid #6e8900;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon:before {
    left: 0;
}

.ribbon:after {
    right: 0;
}

.ribbon-wrapper {
    margin: 0 0 0 212px;
    position: absolute;
}

::-webkit-scrollbar {
    width: 1px;
}

.events4us_moreitemsinmenu ul::after {
    color: rgba(255,255,255,0.5);
    content: "\e134";
    font-family: Glyphicons Halflings;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    margin: 0px 0px 0px 10px;
    text-decoration: none;
    height: 30px;
    display: inline;
    position: absolute;
    text-align: right;
    float: right;
    visibility: visible;
    right: 30px;
    margin-top: -40px;
    bottom: 20px;
}

.events4us_moreitemsinmenu {
    overflow-y: auto;
}

.navbar-collapse.show {
    overflow-y: auto;
}


.navbar-collapse > .show {
    overflow-y: auto;
}

.placeholder {
    outline: 1px dashed #4183C4;
}

.mjs-nestedSortable-error {
    background: #fbe3e4;
    border-color: transparent;
}

#tree {
    width: 550px;
    margin: 0;
}

ol {
    padding-left: 20px;
}

ol.sortable, ol.sortable ol {
    list-style-type: none;
}

.sortable li div {
    border: 1px solid #d4d4d4;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: move;
    border-color: #D4D4D4 #D4D4D4 #BCBCBC;
    margin: 0;
    padding: 3px;
}

li.mjs-nestedSortable-collapsed.mjs-nestedSortable-hovering div {
    border-color: #999;
}

.disclose, .expandEditor {
    cursor: pointer;
    width: 20px;
    display: none;
}

.sortable li.mjs-nestedSortable-collapsed > ol {
    display: none;
}

.sortable li.mjs-nestedSortable-branch > div > .disclose {
    display: inline-block;
}

.sortable span.ui-icon {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.menuDiv {
    background: #EBEBEB;
}

.menuEdit {
    background: #FFF;
}

.itemTitle {
    vertical-align: middle;
    cursor: pointer;
}

.deleteMenu {
    float: right;
    cursor: pointer;
}

.mjs_select {
    background: #fff;
}

.mysettings-trigger {
    margin-top:3px;
    bottom: 40px;
    right: 30px;
    height: 40px;
    width: 40px;
    background: #464dee;
    z-index: 99;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 100%;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -ms-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.editorItemSelected {
    border-width: 3px;
    border-style: solid;
    border-color: black;
}

.disabledFontSelect_FormGroup {
    pointer-events: none;
    /*opacity: 0.4;*/
}

.disabledFontSelect_FontSelect {
    background-color: #eee !important;
}

.disabledFontSelect_FontSelect >span {
    background-color: #eee !important;
}


.profileImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    //background: #512DA8;
    background: #2f3cff;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 150px;
    /*margin: 20px 0;*/
}

.profileImage_small {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #512DA8;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    /*margin: 20px 0;*/
}

.profileImage_smallLogin {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #2f3cff;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    /*margin: 20px 0;*/
}