a.login_account_class {
    color: #FF0000;
    text-decoration: underline;
}

    a.login_account_class:active {
        color: #FF0000;
        text-decoration: underline;
    }

    a.login_account_class:link {
        color: #FF0000;
        text-decoration: underline;
    }

    a.login_account_class:visited {
        color: #FF0000;
        text-decoration: underline;
    }
