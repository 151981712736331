﻿.zoomContainerH {
    width: 1920px;
    height: 1080px;
    background: blue;
}

.zoomContainerV {
    width: 1080px;
    height: 1920px;
    background: blue;
}

.region001_1 {
    grid-area: headerInfo001;
}


.region001_2 {
    grid-area: headerInfo002;
}

.region001_3 {
    grid-area: headerInfo003;
}

.region001_4 {
    grid-area: mainInfo001;
}

.region001_5 {
    grid-area: footerInfo001;
}

.grid-container_001 {
    width: 1920px;
    height: 1080px;
    display: grid;
    grid-template-areas: 'headerInfo001 headerInfo001 headerInfo002 headerInfo002 headerInfo003 headerInfo003' 'mainInfo001 mainInfo001 mainInfo001 mainInfo001 mainInfo001 mainInfo001' 'footerInfo001 footerInfo001 footerInfo001 footerInfo001 footerInfo001 footerInfo001';
    grid-gap: 1px;
    grid-template-rows: 160px 860px 60px;
    grid-template-columns: 320px 320px 320px 320px 320px 320px;
    /*background-color: #2196F3;*/
    padding: 0px;
}

    .grid-container_001 > div {
        position: relative;
        /*background-color: rgba(255, 255, 255, 0.8);*/
        text-align: center;
        padding: 20px 0;
        font-size: 30px;
    }


.region101_1 {
    grid-area: headerInfo101;
}

.region101_2 {
    grid-area: headerInfo102;
}

.region101_3 {
    grid-area: headerInfo103;
}

.region101_4 {
    grid-area: mainInfo101;
}

.region101_5 {
    grid-area: footerInfo101;
}

.grid-container_101 {
    width: 1920px;
    height: 1080px;
    display: grid;
    grid-template-areas: 'headerInfo101 headerInfo101 headerInfo101 headerInfo102 headerInfo102 headerInfo102' 'headerInfo103 headerInfo103 headerInfo103 headerInfo103 headerInfo103 headerInfo103' 'mainInfo101 mainInfo101 mainInfo101 mainInfo101 mainInfo101 mainInfo101' 'footerInfo101 footerInfo101 footerInfo101 footerInfo101 footerInfo101 footerInfo101';
    grid-gap: 1px;
    grid-template-rows: 80px 80px 860px 60px;
    grid-template-columns: 320px 320px 320px 320px 320px 320px;
    /*background-color: #2196F3;*/
    padding: 0px;
}

    .grid-container_101 > div {
        position: relative;
        /*background-color: rgba(255, 255, 255, 0.8);*/
        text-align: center;
        padding: 20px 0;
        font-size: 30px;
    }
