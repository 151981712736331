.jqplot-target {
    margin: 20px;
    height: 340px;
    width: 90%;
    color: #dddddd;
}

.ui-widget-content {
    /*background: rgb(57,57,57);*/
}


table.jqplot-table-legend, table.jqplot-cursor-legend {
    background-color: transparent;
    border: 0px solid #cccccc;
    position: absolute;
    font-size: 20px;
}

