﻿td.details-control {
    background: url('../images/details_open.png') no-repeat center center;
    cursor: pointer;
}

tr.shown td.details-control {
    background: url('../images/details_close.png') no-repeat center center;
}

td.details-up {
    background: url('../images/details_up.png') no-repeat center center;
}

td.details-down {
    background: url('../images/details_down.png') no-repeat center center;
}

td.details-delete {
    background: url('../images/details_delete.png') no-repeat center center;
}


/*COPY PASTE EDGE*/
td input {
    
}